<template>
  <div>
    <b-button
      size="sm"
      class="btn-block"
      variant="primary"
      @click="showModal"
    >
      <feather-icon icon="SendIcon" />
      Mobil App Sms Gönder
    </b-button>
    <b-modal
      v-model="smsModal"
      title="Sms Gönderimi"
      no-close-on-backdrop
      no-enforce-focus
      no-close-on-esc
      centered
      hide-footer
    >
      <template v-if="controlData.status && !marketingSaveStatus.status && !marketingSaveStatus.message">
        <b-alert
          show
          class="m-1"
          :variant="controlData.status ? 'info':'danger'"
        >
          <div class="alert-body text-center">
            {{ controlData.message }}
          </div>
        </b-alert>
        <b-table-simple
          small
          responsive
          fixed
          borderless
        >
          <b-thead>
            <b-tr>
              <b-th>Müşteri</b-th>
              <b-th>Telefon</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>
                {{ controlData.customer.name }}
              </b-td>
              <b-td>
                {{ controlData.customer.phone }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <hr>
        <b-button
          variant="primary"
          class="btn-block"
          small
          :disabled="smsSending"
          @click="sendSms"
        >
          <b-spinner
            v-if="smsSending"
            class="mr-1"
            small
          />
          <feather-icon icon="SendIcon" />
          Sms Gönder
        </b-button>
      </template>
      <template v-else>
        <template v-if="marketingSaveStatus.message">
          <b-alert
            show
            class="m-1"
            :variant="marketingSaveStatus.status ? 'success':'danger'"
          >
            <div class="alert-body text-center">
              {{ marketingSaveStatus.message }}
            </div>
          </b-alert>
        </template>
        <template v-else>
          <b-alert
            show
            class="m-1"
            :variant="controlData.status ? 'info':'danger'"
          >
            <div class="alert-body text-center">
              {{ controlData.message }}
            </div>
          </b-alert>
        </template>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal, BAlert, BTableSimple, BThead, BTh, BTbody, BTr, BTd, BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'MarketingSmsCard',
  components: {
    BButton,
    BModal,
    BAlert,
    BTableSimple,
    BThead,
    BTh,
    BTbody,
    BTr,
    BTd,
    BSpinner,
  },
  props: {
    idComCustomer: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      smsModal: false,

    }
  },
  computed: {
    controlData() {
      return this.$store.getters['marketingSms/controlStatus']
    },
    marketingSaveStatus() {
      return this.$store.getters['marketingSms/marketingSmsSaveStatus']
    },
    smsSending() {
      return this.$store.getters['marketingSms/dataLoading']
    },
  },
  methods: {
    customerControl() {
      this.$store.commit('marketingSms/RESET_CONTROL')
      this.$store.commit('marketingSms/RESET_SAVESMS')
      this.$store.dispatch('marketingSms/customerControl', this.idComCustomer)
    },
    sendSms() {
      this.$store.dispatch('marketingSms/sendSms', this.idComCustomer)
    },
    showModal() {
      this.customerControl()
      this.smsModal = true
    },
  },
}
</script>
