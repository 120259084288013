<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >
      <app-collapse-item
        v-if="history.interview.length"
        title="Görüşme Geçmişi"
      >
        <b-list-group>
          <interview-history
            v-for="item in history.interview"
            :key="item.id"
            :item-data="item"
          />
        </b-list-group>
      </app-collapse-item>

      <app-collapse-item
        v-if="history.sale_orderform.length"
        title="Sıfır Satış"
      >
        <b-list-group>
          <sale-history
            v-for="item in history.sale_orderform"
            :key="item.id"
            :item-data="item"
          />
        </b-list-group>
      </app-collapse-item>

      <app-collapse-item
        v-if="history.service_tracing.length"
        title="Servis Randevusu"
      >
        <b-list-group>
          <service-history
            v-for="item in history.service_tracing"
            :key="item.id"
            :item-data="item"
          />
        </b-list-group>
      </app-collapse-item>

      <app-collapse-item
        v-if="history.crm.length"
        title="Müşteri İlişkileri"
      >
        <b-list-group>
          <crm-history
            v-for="item in history.crm"
            :key="item.id"
            :item-data="item"
          />
        </b-list-group>
      </app-collapse-item>

      <app-collapse-item
        v-if="history.realty_sale.length"
        title="Gayrimenkul Satış"
      >
        <b-list-group>
          <realty-sale-history
            v-for="item in history.realty_sale"
            :key="item.id"
            :item-data="item"
          />
        </b-list-group>
      </app-collapse-item>

      <app-collapse-item
        v-if="history.rental_contracts.length"
        title="Filo Kiralama"
      >
        <b-list-group>
          <rental-contracts-history
            v-for="item in history.rental_contracts"
            :key="item.id"
            :item-data="item"
          />
        </b-list-group>
      </app-collapse-item>
    </app-collapse>
    <div v-if="!history.interview.length && !history.sale_orderform.length && !history.service_tracing.length && !history.crm.length && !history.realty_sale.length && !history.rental_contracts.length">
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body text-center">
          Müşteri geçmişi bulunmamaktadır.
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BListGroup, BAlert } from 'bootstrap-vue'
import InterviewHistory from '@/views/Customers/view/History/InterviewHistory.vue'
import SaleHistory from '@/views/Customers/view/History/SaleHistory.vue'
import ServiceHistory from '@/views/Customers/view/History/ServiceHistory.vue'
import CrmHistory from '@/views/Customers/view/History/CrmHistory.vue'
import RealtySaleHistory from '@/views/Customers/view/History/RealtySaleHistory.vue'
import RentalContractsHistory from '@/views/Customers/view/History/RentalContractsHistory.vue'

export default {
  name: 'History',
  components: {
    AppCollapse,
    AppCollapseItem,
    InterviewHistory,
    SaleHistory,
    ServiceHistory,
    CrmHistory,
    RealtySaleHistory,
    RentalContractsHistory,
    BListGroup,
    BAlert,
  },
  computed: {
    history() {
      return this.$store.getters['customers/getHistory']
    },
  },
}
</script>
