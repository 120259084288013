<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <customer-cars />
        </b-card>
      </b-col>
      <b-col
        v-if="serviceControl.id"
        cols="12"
      >
        <b-alert
          variant="info"
          show
        >
          <div class="alert-body text-center">
            <p class="lead">
              Seçili araç kartı için açık servis randevusu bulunmaktadır.
            </p>
            <p>
              Randevu Durumu: {{ serviceControl.status }}
            </p>
            <b-button
              variant="danger"
              size="sm"
              :to="'/service/view/' + serviceControl.id"
            >
              Randevuyu Görüntüle
            </b-button>
          </div>
        </b-alert>
      </b-col>
      <b-col v-if="service.id_com_cars">
        <b-card title="Randevu Bilgisi">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <service-tracing-brand-select-card />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <service-tracing-location-select-card />
            </b-col>
            <b-col cols="12">
              <service-user />
            </b-col>
            <b-col cols="12">
              <app-date-time />
            </b-col>
            <b-col cols="12">
              <service-type />
            </b-col>
            <b-col cols="12">
              <km />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <rental-status />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <vale-status />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <email-status />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <meets />
            </b-col>
            <b-col cols="12">
              <customer-request />
            </b-col>
          </b-row>
        </b-card>
        <save-button :action-methods="saveData" />
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BCard, BAlert, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import CustomerCars from '@/views/Service/elements/CustomerCars.vue'
import ServiceUser from '@/views/Service/elements/ServiceUser.vue'
import AppDateTime from '@/views/Service/elements/AppDateTime.vue'
import ServiceType from '@/views/Service/elements/ServiceType.vue'
import Km from '@/views/Service/elements/Km.vue'
import RentalStatus from '@/views/Service/elements/RentalStatus.vue'
import ValeStatus from '@/views/Service/elements/ValeStatus.vue'
import EmailStatus from '@/views/Service/elements/EmailStatus.vue'
import Meets from '@/views/Service/elements/Meets.vue'
import CustomerRequest from '@/views/Service/elements/CustomerRequest.vue'
import SaveButton from '@/views/Service/elements/SaveButton.vue'
import ServiceTracingBrandSelectCard from '@/views/Service/elements/BrandSelect.vue'
import ServiceTracingLocationSelectCard from '@/views/Service/elements/LocationCard.vue'

export default {
  name: 'ServiceForm',
  components: {
    ServiceTracingLocationSelectCard,
    ServiceTracingBrandSelectCard,
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    CustomerCars,
    ServiceUser,
    AppDateTime,
    ServiceType,
    Km,
    RentalStatus,
    ValeStatus,
    EmailStatus,
    Meets,
    CustomerRequest,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    serviceControl() {
      return this.$store.getters['serviceTracing/getServiceControl']
    },
    saveStatus() {
      return this.$store.getters['serviceTracing/getServiceSaveStatus']
    },
  },
  watch: {
    service: {
      deep: true,
      handler(val) {
        if (val.id_com_cars) {
          if (!val.id) {
            this.$store.dispatch('serviceTracing/serviceControl', val.id_com_cars)
          }
        } else {
          this.serviceControl.id = null
          this.serviceControl.status = null
        }
      },
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push(`/service/view/${val.id}`)
        this.$store.commit('serviceTracing/RESET_ITEM_DATA')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.service.submitStatus = false
    },
  },
  created() {
    this.$store.commit('serviceTracing/RESET_ITEM_DATA')
    localize('tr')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.service.submitStatus = true
          this.service.id_com_customer = this.$route.params.id
          this.$store.dispatch('serviceTracing/serviceSave', this.service)
        }
      })
    },
  },
}
</script>
